import React, { useState } from 'react';
import { Card, Checkbox, Col, DatePicker, Form, Input, Row } from 'antd';
import { inputSize, textInputSize } from '../../Shared/UI/jsx';
import { SearchOutlined } from '@ant-design/icons';
import DevelopmentalTherapistTable from '../../Shared/UI/SelectTables/DevelopmentalTherapist'
import DTCTable from '../../Shared/UI/SelectTables/DTC';
import OccupationalTherapistTable from '../../Shared/UI/SelectTables/OccupationalTherapist';
import COTATable from '../../Shared/UI/SelectTables/COTA';
import PhysicalTherapistTable from '../../Shared/UI/SelectTables/PhysicalTherapist';
import PTATable from '../../Shared/UI/SelectTables/PTA';
import SpeechTherapistTable from '../../Shared/UI/SelectTables/SpeechTherapist';
import SLPCFTable from '../../Shared/UI/SelectTables/SLPCF';
import { FormDescription } from '../../Shared/Consts/FormDescription';

const { Item } = Form;
const { TextArea } = Input;

const Therapists = ({ form, formContext, setFormContext }) => {
    const [developmentalTherapistIdVisible, setdevelopmentalTherapistIdVisible] = useState(false);
    const [dTCVisible, setDTCVisible] = useState(false);
    const [dTCSupervisorVisible, setDTCSupervisorVisible] = useState(false);
    const [occupationalTherapistVisible, setOccupationalTherapistVisible] = useState(false);
    const [cOTAVisible, setCOTAVisible] = useState(false);
    const [cOTASupervisorVisible, setCOTASupervisorVisible] = useState(false);
    const [physicalTherapistVisible, setPhysicalTherapistVisible] = useState(false);
    const [pTAVisible, setPTAVisible] = useState(false);
    const [pTASupervisorVisible, setPTASupervisorVisible] = useState(false);
    const [speechTherapistVisible, setSpeechTherapistVisible] = useState(false);
    const [sLPCFVisible, setSLPCFVisible] = useState(false);
    const [sLPCFSupervisorVisible, setSLPCFSupervisorVisible] = useState(false);

    const handleDTSubmit = record => {
        setFormContext({ ...formContext, DT: record});
        form.setFieldsValue({developmentalTherapistId: record.id})
    }

    const handleDTCSubmit = record => {
        setFormContext({ ...formContext, DTC: record, DTSupervisor: formContext.DT});
        form.setFieldsValue({dtcId: record.id, dtcSupervisorId: formContext?.DT?.id});
    }

    const handleDTSupervisorSubmit = record => {
        setFormContext({ ...formContext, DTSupervisor: record});
        form.setFieldsValue({dtcSupervisorId: record.id})
    }

    const handleOTSubmit = record => {
        setFormContext({ ...formContext, OT: record});
        form.setFieldsValue({occupationalTherapistId: record.id})
    }

    const handleCOTASubmit = record => {
        setFormContext({ ...formContext, COTA: record, COTASupervisor: formContext.OT});
        form.setFieldsValue({cotaId: record.id, cotaSupervisorId: formContext?.OT?.id});
    }

    const handleCOTASupervisorSubmit = record => {
        setFormContext({ ...formContext, COTASupervisor: record});
        form.setFieldsValue({cotaSupervisorId: record.id})
    }

    const handlePTSubmit = record => {
        setFormContext({ ...formContext, PT: record});
        form.setFieldsValue({physicalTherapistId: record.id})
    }
    
    const handlePTASubmit = record => {
        setFormContext({ ...formContext, PTA: record, PTASupervisor: formContext.PT});
        form.setFieldsValue({ptaId: record.id, ptaSupervisorId: formContext?.PT.id})
    }

    const handlePTSupervisorSubmit = record => {
        setFormContext({ ...formContext, PTASupervisor: record});
        form.setFieldsValue({ptaSupervisorId: record.id})
    }

    const handleSLPSubmit = record => {
        setFormContext({ ...formContext, SLP: record});
        form.setFieldsValue({speechTherapistId: record.id})
    }

    const handleSLPCFSubmit = record => {
        setFormContext({ ...formContext, SLPCF: record, SLPCFSupervisor: formContext.SLP});
        form.setFieldsValue({slpcfId: record.id, slpcfSupervisorId: formContext?.SLP?.id})
    }

    const handleSLPCFSupervisorSubmit = record => {
        setFormContext({ ...formContext, SLPCFSupervisor: record});
        form.setFieldsValue({sLPCFSupervisor: record.id})
    }

    return (
        <Form
            form={form}
            size="small"
            layout="vertical"
        >
            <Row gutter={[8, 8]}>
                <Card title="Developmental Therapist" className='formCard'>
                    <Row>
                        {/* Developmental Therapist */}
                        <Col span={12}>
                            <Item name="dTServicesStartBy" label="DT Services Start By">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="dTInitialIFSPDateSOS" label="DT Initial IFSP SOS">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="dTProviderSOSDate" label="DT Provider SOS">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="dTDateAssigned" label="DT Date Assigned">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="dtServiceFromAnotherAgency" valuePropName="checked">
                                <Checkbox>
                                    DT Other Agency?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="developmentalTherapistId" label="Developmental Therapist">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setdevelopmentalTherapistIdVisible(!developmentalTherapistIdVisible)} />
                                            <DevelopmentalTherapistTable visibility={developmentalTherapistIdVisible} setVisibility={setdevelopmentalTherapistIdVisible} handleSubmit={handleDTSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.DT ? (
                                <FormDescription description={formContext.DT.preferredName + " " + formContext.DT.lastName} />
                            ) : null}
                        </Col>
                        <Col span={12}>
                            <Item name="dtcId" label="DTC">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setDTCVisible(!dTCVisible)} />
                                            <DTCTable visibility={dTCVisible} setVisibility={setDTCVisible} handleSubmit={handleDTCSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.DTC ? (
                                <FormDescription description={formContext.DTC.preferredName + " " + formContext.DTC.lastName} />
                            ) : null}
                        </Col>
                        <Col span={12}>
                            <Item name="dtcSupervisorId" label="DT Supervisor">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setDTCSupervisorVisible(!dTCSupervisorVisible)} />
                                            <developmentalTherapistTable visibility={dTCSupervisorVisible} setVisibility={setDTCSupervisorVisible} handleSubmit={handleDTSupervisorSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.DTSupervisor ? (
                                <FormDescription description={formContext.DTSupervisor.preferredName + " " + formContext.DTSupervisor.lastName} />
                            ) : null}
                        </Col>
                    </Row>
                </Card>
                {/* Occupational Therapist */}
                <Card title="Occupational Therapist" className='formCard'>
                    <Row>
                        <Col span={12}>
                            <Item name="oTServicesStartBy" label="OT Services Start By">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="oTInitialIFSPDateSOS" label="OT Initial IFSP SOS">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="oTProviderSOSDate" label="OT Provider SOS">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="oTDateAssigned" label="OT Date Assigned">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="otServiceFromAnotherAgency" valuePropName="checked">
                                <Checkbox>
                                    OT Other Agency?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="occupationalTherapistId" label="Occupational Therapist">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setOccupationalTherapistVisible(!occupationalTherapistVisible)} />
                                            <OccupationalTherapistTable visibility={occupationalTherapistVisible} setVisibility={setOccupationalTherapistVisible} handleSubmit={handleOTSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.OT ? (
                                <FormDescription description={formContext.OT.preferredName + " " + formContext.OT.lastName} />
                            ) : null}
                        </Col>
                        <Col span={12}>
                            <Item name="cotaId" label="COTA">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setCOTAVisible(!cOTAVisible)} />
                                            <COTATable visibility={cOTAVisible} setVisibility={setCOTAVisible} handleSubmit={handleCOTASubmit}/>
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.COTA ? (
                                <FormDescription description={formContext.COTA.preferredName + " " + formContext.COTA.lastName} />
                            ) : null}
                        </Col>
                        <Col span={12}>
                            <Item name="cotaSupervisorId" label="COTA Supervisor">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setCOTASupervisorVisible(!cOTASupervisorVisible)} />
                                            <OccupationalTherapistTable visibility={cOTASupervisorVisible} setVisibility={setCOTASupervisorVisible} handleSubmit={handleCOTASupervisorSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                        </Col>
                    </Row>
                </Card>
                {/* Physical Therapist */}
                <Card title="Physical Therapist" className='formCard'>
                    <Row>
                        <Col span={12}>
                            <Item name="pTServicesStartBy" label="PT Services Start By">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="pTInitialIFSPDateSOS" label="PT Initial IFSP SOS">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="pTProviderSOSDate" label="PT Provider SOS">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="pTDateAssigned" label="PT Date Assigned">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="ptServiceFromAnotherAgency" valuePropName="checked">
                                <Checkbox>
                                    PT Other Agency?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="physicalTherapistId" label="Physical Therapist">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setPhysicalTherapistVisible(!physicalTherapistVisible)} />
                                            <PhysicalTherapistTable visibility={physicalTherapistVisible} setVisibility={setPhysicalTherapistVisible} handleSubmit={handlePTSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.PT ? (
                                <FormDescription description={formContext.PT.preferredName + " " + formContext.PT.lastName} />
                            ) : null}
                        </Col>
                        <Col span={12}>
                            <Item name="ptaId" label="PTA">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setPTAVisible(!pTAVisible)} />
                                            <PTATable visibility={pTAVisible} setVisibility={setPTAVisible} handleSubmit={handlePTASubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.PTA ? (
                                <FormDescription description={formContext.PTA.preferredName + " " + formContext.PTA.lastName} />
                            ) : null}
                        </Col>
                        <Col span={12}>
                            <Item name="ptaSupervisorId" label="PTA Supervisor">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setPTASupervisorVisible(!pTASupervisorVisible)} />
                                            <PhysicalTherapistTable visibility={pTASupervisorVisible} setVisibility={setPTASupervisorVisible} handleSubmit={handlePTSupervisorSubmit}/>
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.PTASupervisor ? (
                                <FormDescription description={formContext.PTASupervisor.preferredName + " " + formContext.PTASupervisor.lastName} />
                            ) : null}
                        </Col>
                    </Row>
                </Card>
                {/* Speech Therapist */}
                <Card title="Speech Therapist" className='formCard'>
                    <Row>
                        <Col span={12}>
                            <Item name="sTServicesStartBy" label="ST Services Start By">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="sTInitialIFSPDateSOS" label="ST Initial IFSP SOS">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="sTProviderSOSDate" label="ST Provider SOS">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="sTDateAssigned" label="ST Date Assigned">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="stServiceFromAnotherAgency" valuePropName="checked">
                                <Checkbox>
                                    ST Other Agency?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="speechTherapistId" label="Speech Therapist">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setSpeechTherapistVisible(!speechTherapistVisible)} />
                                            <SpeechTherapistTable visibility={speechTherapistVisible} setVisibility={setSpeechTherapistVisible} handleSubmit={handleSLPSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.SLP ? (
                                <FormDescription description={formContext.SLP.preferredName + " " + formContext.SLP.lastName} />
                            ) : null}
                        </Col>
                        <Col span={12}>
                            <Item name="slpcfId" label="SLPCF">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setSLPCFVisible(!sLPCFVisible)} />
                                            <SLPCFTable visibility={sLPCFVisible} setVisibility={setSLPCFVisible} handleSubmit={handleSLPCFSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.SLPCF ? (
                                <FormDescription description={formContext.SLPCF.preferredName + " " + formContext.SLPCF.lastName} />
                            ) : null}
                        </Col>
                        {/* <Col span={12}>
                            <Item name="slpcfSupervisorId" label="SLPCF Supervisor">
                                <Input
                                    style={inputSize}
                                    addonAfter={
                                        <>
                                            <SearchOutlined onClick={() => setSLPCFSupervisorVisible(!sLPCFSupervisorVisible)} />
                                            <SpeechTherapistTable visibility={sLPCFSupervisorVisible} setVisibility={setSLPCFSupervisorVisible} handleSubmit={handleSLPCFSupervisorSubmit} />
                                        </>
                                    }
                                />
                            </Item>
                            {formContext && formContext.SLPCFSupervisor ? (
                                <FormDescription description={formContext.SLPCFSupervisor.preferredName + " " + formContext.SLPCFSupervisor.lastName} />
                            ) : null}
                        </Col> */}
                    </Row>
                </Card>
                <Card title="Reports" className='formCard'>
                    <Row>
                        <Col span={12}>
                            <Item name="quaterlyReport5Month" label="Quarterly Report - 5 month">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="quarterlyReport10Month" label="Quarterly Report - 10 month">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="otherReport" label="Other Report">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="dischargeReport" label="Discharge Report">
                                <DatePicker format="MM/DD/YYYY" style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="exitSkills" label="Exit Skills">
                                <TextArea style={textInputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item name="notes" label="Notes">
                                <TextArea style={textInputSize} />
                            </Item>
                        </Col>
                        <Col span={12}>
                        <Item name="checkNotes" valuePropName="checked">
                            <Checkbox>
                                Check Notes?
                            </Checkbox>
                        </Item>
                    </Col>
                    </Row>
                </Card>
            </Row >
        </Form >
    )
}

export default Therapists;