import { Button, Form, Modal, Popconfirm, Steps, Card, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppSettingsContext, FormContext } from '../../Shared/Contexts';
import { reusableSubmitValidatorWithKeys } from '../../Shared/Functions';
import { allFormKeys } from './FormKeys';
import moment, { months } from "moment/moment";
import SequentialMessageNotifier from '../../Shared/Functions/SequentialMessageNotifier';
import { CreateClient, EditClient } from '../../Shared/Services';
import Guardians from './Guardians';
import Services from './Services';
import Therapists from './Therapists';
import Languages from './Languages';
import PersonalAndAddress from './PersonalAndAddress';

const { Step } = Steps;

const ClientForm = ({
    formType,
    setFormType,
    visibility,
    setVisibility,
    highlighted,
    handleAfterFormSubmit
}) => {
    const [form] = Form.useForm();
    const { formContext, setFormContext } = useContext(FormContext);
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false);
    const [notesVisible, setNotesVisible] = useState(false);

    useEffect(() => {
        if (visibility) {
            if (highlighted?.checkNotes) {
                setNotesVisible(true);
            }
            if (formType === 'edit') {
                console.log(highlighted);
                form.setFieldsValue({
                    firstStepsId: highlighted?.firstStepsId,
                    iFSPDate: highlighted.iFSPDate ? moment(highlighted.iFSPDate, 'YYYY-MM-DD') : '',
                    firstName1: highlighted.firstName1 ? highlighted.firstName1 : '',
                    firstName2: highlighted.firstName2 ? highlighted.firstName2 : '',
                    preferredName: highlighted.preferredName ? highlighted.preferredName : '',
                    lastName1: highlighted.lastName1 ? highlighted.lastName1 : '',
                    lastName2: highlighted.lastName2 ? highlighted.lastName2 : '',
                    lastNameHyphenated: highlighted.lastNameHyphenated ? highlighted.lastNameHyphenated : false,
                    county: highlighted.county ? highlighted.county.toString() : '',
                    dateOfBirth: highlighted.dateOfBirth ? moment(highlighted.dateOfBirth, 'YYYY-MM-DD') : '',
                    ageOut: highlighted.ageOut ? moment(highlighted.ageOut, 'YYYY-MM-DD') : '',
                    address: highlighted.address ? highlighted.address : '',
                    city: highlighted.city ? highlighted.city : '',
                    zipcode: highlighted.zipcode ? highlighted.zipcode : '',
                    parentOneFirstName1: highlighted.parentOneFirstName1 ? highlighted.parentOneFirstName1 : '',
                    parentOneFirstName2: highlighted.parentOneFirstName2 ? highlighted.parentOneFirstName2 : '',
                    parentOnePreferredName: highlighted.parentOnePreferredName ? highlighted.parentOnePreferredName : '',
                    parentOneLastName1: highlighted.parentOneLastName1 ? highlighted.parentOneLastName1 : '',
                    parentOneLastName2: highlighted.parentOneLastName2 ? highlighted.parentOneLastName2 : '',
                    parentOneLastNameHyphenated: highlighted.parentOneLastNameHyphenated ? highlighted.parentOneLastNameHyphenated : false,
                    parentOneAddress1: highlighted.parentOneAddress1 ? highlighted.parentOneAddress1 : '',
                    parentOneAddress2: highlighted.parentOneAddress2 ? highlighted.parentOneAddress2 : '',
                    parentOneCity: highlighted.parentOneCity ? highlighted.parentOneCity : '',
                    parentOneState: highlighted.parentOneState ? highlighted.parentOneState : '',
                    parentOneZipcode: highlighted.parentOneZipcode ? highlighted.parentOneZipcode : '',
                    parentOnePhone: highlighted.parentOnePhone ? highlighted.parentOnePhone : '',
                    parentOneNotes: highlighted.parentOneNotes ? highlighted.parentOneNotes : '',
                    parentTwoFirstName1: highlighted.parentTwoFirstName1 ? highlighted.parentTwoFirstName1 : '',
                    parentTwoFirstName2: highlighted.parentTwoFirstName2 ? highlighted.parentTwoFirstName2 : '',
                    parentTwoPreferredName: highlighted.parentTwoPreferredName ? highlighted.parentTwoPreferredName : '',
                    parentTwoLastName1: highlighted.parentTwoLastName1 ? highlighted.parentTwoLastName1 : '',
                    parentTwoLastName2: highlighted.parentTwoLastName2 ? highlighted.parentTwoLastName2 : '',
                    parentTwoLastNameHyphenated: highlighted.parentTwoLastNameHyphenated ? highlighted.parentTwoLastNameHyphenated : false,
                    parentTwoAddress1: highlighted.parentTwoAddress1 ? highlighted.parentTwoAddress1 : '',
                    parentTwoAddress2: highlighted.parentTwoAddress2 ? highlighted.parentTwoAddress2 : '',
                    parentTwoCity: highlighted.parentTwoCity ? highlighted.parentTwoCity : '',
                    parentTwoState: highlighted.parentTwoState ? highlighted.parentTwoState : '',
                    parentTwoZipcode: highlighted.parentTwoZipcode ? highlighted.parentTwoZipcode : '',
                    parentTwoPhone: highlighted.parentTwoPhone ? highlighted.parentTwoPhone : '',
                    parentTwoNotes: highlighted.parentTwoNotes ? highlighted.parentTwoNotes : '',
                    fosterParentFirstName1: highlighted.fosterParentFirstName1 ? highlighted.fosterParentFirstName1 : '',
                    fosterParentFirstName2: highlighted.fosterParentFirstName2 ? highlighted.fosterParentFirstName2 : '',
                    fosterParentPreferredName: highlighted.fosterParentPreferredName ? highlighted.fosterParentPreferredName : '',
                    fosterParentLastName1: highlighted.fosterParentLastName1 ? highlighted.fosterParentLastName1 : '',
                    fosterParentLastName2: highlighted.fosterParentLastName2 ? highlighted.fosterParentLastName2 : '',
                    fosterParentLastNameHyphenated: highlighted.fosterParentLastNameHyphenated ? highlighted.fosterParentLastNameHyphenated : false,
                    fosterParentAddress1: highlighted.fosterParentAddress1 ? highlighted.fosterParentAddress1 : '',
                    fosterParentAddress2: highlighted.fosterParentAddress2 ? highlighted.fosterParentAddress2 : '',
                    fosterParentCity: highlighted.fosterParentCity ? highlighted.fosterParentCity : '',
                    fosterParentState: highlighted.fosterParentState ? highlighted.fosterParentState : '',
                    fosterParentZipcode: highlighted.fosterParentZipcode ? highlighted.fosterParentZipcode : '',
                    fosterParentPhone: highlighted.fosterParentPhone ? highlighted.fosterParentPhone : '',
                    fosterParentNotes: highlighted.fosterParentNotes ? highlighted.fosterParentNotes : '',
                    guardianAdLitumParentFirstName1: highlighted.guardianAdLitumParentFirstName1 ? highlighted.guardianAdLitumParentFirstName1 : '',
                    guardianAdLitumFirstName2: highlighted.guardianAdLitumFirstName2 ? highlighted.guardianAdLitumFirstName2 : '',
                    guardianAdLitumPreferredName: highlighted.guardianAdLitumPreferredName ? highlighted.guardianAdLitumPreferredName : '',
                    guardianAdLitumLastName1: highlighted.guardianAdLitumLastName1 ? highlighted.guardianAdLitumLastName1 : '',
                    guardianAdLitumLastName2: highlighted.guardianAdLitumLastName2 ? highlighted.guardianAdLitumLastName2 : '',
                    guardianAdLitumLastNameHyphenated: highlighted.guardianAdLitumLastNameHyphenated ? highlighted.guardianAdLitumLastNameHyphenated : false,
                    guardianAdLitumAddress1: highlighted.guardianAdLitumAddress1 ? highlighted.guardianAdLitumAddress1 : '',
                    guardianAdLitumAddress2: highlighted.guardianAdLitumAddress2 ? highlighted.guardianAdLitumAddress2 : '',
                    guardianAdLitumCity: highlighted.guardianAdLitumCity ? highlighted.guardianAdLitumCity : '',
                    guardianAdLitumState: highlighted.guardianAdLitumState ? highlighted.guardianAdLitumState : '',
                    guardianAdLitumZipcode: highlighted.guardianAdLitumZipcode ? highlighted.guardianAdLitumZipcode : '',
                    guardianAdLitumPhone: highlighted.guardianAdLitumPhone ? highlighted.guardianAdLitumPhone : '',
                    guardianAdLitumNotes: highlighted.guardianAdLitumNotes ? highlighted.guardianAdLitumNotes : '',
                    guardianFirstName1: highlighted.guardianFirstName1 ? highlighted.guardianFirstName1 : '',
                    guardianFirstName2: highlighted.guardianFirstName2 ? highlighted.guardianFirstName2 : '',
                    guardianPreferredName: highlighted.guardianPreferredName ? highlighted.guardianPreferredName : '',
                    guardianLastName1: highlighted.guardianLastName1 ? highlighted.guardianLastName1 : '',
                    guardianLastName2: highlighted.guardianLastName2 ? highlighted.guardianLastName2 : '',
                    guardianLastNameHyphenated: highlighted.guardianAdLitumLastNameHyphenated ? highlighted.guardianAdLitumLastNameHyphenated : false,
                    guardianAddress1: highlighted.guardianAddress1 ? highlighted.guardianAddress1 : '',
                    guardianAddress2: highlighted.guardianAddress2 ? highlighted.guardianAddress2 : '',
                    guardianCity: highlighted.guardianCity ? highlighted.guardianCity : '',
                    guardianState: highlighted.guardianState ? highlighted.guardianState : '',
                    guardianZipcode: highlighted.guardianZipcode ? highlighted.guardianZipcode : '',
                    guardianPhone: highlighted.guardianPhone ? highlighted.guardianPhone : '',
                    guardianNotes: highlighted.guardianNotes ? highlighted.guardianNotes : '',
                    serviceCoordinatorId: highlighted.serviceCoordinatorId ? highlighted.serviceCoordinatorId : null,
                    serviceCoordinatorNotes: highlighted.serviceCoordinatorNotes ? highlighted.serviceCoordinatorNotes : '',
                    virtualVisit: highlighted?.virtualVisit,
                    dTConsult: highlighted.dTConsult ? moment(highlighted.dTConsult, 'YYYY=MM-DD') : '',
                    oTConsult: highlighted.oTConsult ? moment(highlighted.oTConsult, 'YYYY=MM-DD') : '',
                    pTConsult: highlighted.pTConsult ? moment(highlighted.pTConsult, 'YYYY=MM-DD') : '',
                    sTConsult: highlighted.sTConsult ? moment(highlighted.sTConsult, 'YYYY=MM-DD') : '',
                    dTNeeded: highlighted?.dTNeeded,
                    oTNeeded: highlighted?.oTNeeded,
                    pTNeeded: highlighted?.pTNeeded,
                    sTNeeded: highlighted?.sTNeeded,
                    dTFulfilled: highlighted?.dTFulfilled,
                    dTAFulfilled: highlighted?.dTAFulfilled,
                    oTFulfilled: highlighted?.oTFulfilled,
                    cOTAFulfilled: highlighted?.cOTAFulfilled,
                    pTFulfilled: highlighted?.pTFulfilled,
                    pTAFulfilled: highlighted?.pTAFulfilled,
                    sTFulfilled: highlighted?.sTFulfilled,
                    sLPCFFulfilled: highlighted?.sLPCFFulfilled,
                    initialReferralDate: highlighted.initialReferralDate ? moment(highlighted.initialReferralDate, 'YYYY-MM-DD') : '',
                    consultReferral1Date: highlighted.consultReferral1Date ? moment(highlighted.consultReferral1Date, 'YYYY-MM-DD') : '',
                    consultReferral2Date: highlighted.consultReferral2Date ? moment(highlighted.consultReferral2Date, 'YYYY-MM-DD') : '',
                    consultReferral3Date: highlighted.consultReferral3Date ? moment(highlighted.consultReferral3Date, 'YYYY-MM-DD') : '',
                    consultReferral4Date: highlighted.consultReferral4Date ? moment(highlighted.consultReferral4Date, 'YYYY-MM-DD') : '',
                    ongoingReferral1Date: highlighted.ongoingReferral1Date ? moment(highlighted.ongoingReferral1Date, 'YYYY-MM-DD') : '',
                    ongoingReferral2Date: highlighted.ongoingReferral2Date ? moment(highlighted.ongoingReferral2Date, 'YYYY-MM-DD') : '',
                    ongoingReferral3Date: highlighted.ongoingReferral3Date ? moment(highlighted.ongoingReferral3Date, 'YYYY-MM-DD') : '',
                    ongoingReferral4Date: highlighted.ongoingReferral4Date ? moment(highlighted.ongoingReferral4Date, 'YYYY-MM-DD') : '',
                    addonReferral1Date: highlighted.addonReferral1Date ? moment(highlighted.addonReferral1Date, 'YYYY-MM-DD') : '',
                    addonReferral2Date: highlighted.addonReferral2Date ? moment(highlighted.addonReferral2Date, 'YYYY-MM-DD') : '',
                    addonReferral3Date: highlighted.addonReferral3Date ? moment(highlighted.addonReferral3Date, 'YYYY-MM-DD') : '',
                    addonReferral4Date: highlighted.addonReferral4Date ? moment(highlighted.addonReferral4Date, 'YYYY-MM-DD') : '',
                    caseManagementNotes: highlighted.caseManagementNotes ? highlighted.caseManagementNotes : '',
                    supplementalDate1: highlighted.supplementalDate1 ? moment(highlighted.supplementalDate1, 'YYYY-MM-DD') : '',
                    supplementalService1: highlighted.supplementalService1 ? highlighted.supplementalService1.toString() : '',
                    supplementalDate2: highlighted.supplementalDate2 ? moment(highlighted.supplementalDate2, 'YYYY-MM-DD') : '',
                    supplementalService2: highlighted.supplementalService2 ? highlighted.supplementalService2.toString() : '',
                    supplementalDate3: highlighted.supplementalDate3 ? moment(highlighted.supplementalDate3, 'YYYY-MM-DD') : '',
                    supplementalService3: highlighted.supplementalService3 ? highlighted.supplementalService3.toString() : '',
                    supplementalDate4: highlighted.supplementalDate4 ? moment(highlighted.supplementalDate4, 'YYYY-MM-DD') : '',
                    supplementalService4: highlighted.supplementalService3 ? highlighted.supplementalService4.toString() : '',
                    doctorSignature: highlighted.doctorSignature ? highlighted.doctorSignature : '',
                    dTServicesStartBy: highlighted.dTServicesStartBy ? moment(highlighted.dTServicesStartBy, 'YYYY-MM-DD') : '',
                    dTInitialIFSPDateSOS: highlighted.dTInitialIFSPDateSOS ? moment(highlighted.dTInitialIFSPDateSOS, 'YYYY-MM-DD') : '',
                    dTProviderSOSDate: highlighted.dTProviderSOSDate ? moment(highlighted.dTProviderSOSDate, 'YYYY-MM-DD') : '',
                    dTDateAssigned: highlighted.dTDateAssigned ? moment(highlighted.dTDateAssigned, 'YYYY-MM-DD') : '',
                    dTServiceFromAnotherAgency: highlighted?.dTServiceFromAnotherAgency,
                    developmentalTherapistId: highlighted.developmentalTherapistId ? highlighted.developmentalTherapistId.toString() : null,
                    dTCId: highlighted.dTCId ? highlighted.dTCId.toString() : null,
                    dTCSupervisorId: highlighted.dTCSupervisorId ? highlighted.dTCSupervisorId.toString() : null,
                    oTServicesStartBy: highlighted.oTServicesStartBy ? moment(highlighted.oTServicesStartBy, 'YYYY-MM-DD') : '',
                    oTInitialIFSPDateSOS: highlighted.oTInitialIFSPDateSOS ? moment(highlighted.oTInitialIFSPDateSOS, 'YYYY-MM-DD') : '',
                    oTProviderSOSDate: highlighted.oTProviderSOSDate ? moment(highlighted.oTProviderSOSDate, 'YYYY-MM-DD') : '',
                    oTDateAssigned: highlighted.oTDateAssigned ? moment(highlighted.oTDateAssigned, 'YYYY-MM-DD') : '',
                    oTServiceFromAnotherAgency: highlighted?.oTServiceFromAnotherAgency,
                    occupationalTherapistId: highlighted.occupationalTherapistId ? highlighted?.occupationalTherapistId.toString() : null,
                    cOTAId: highlighted.cOTAId ? highlighted.cOTAId.toString() : null,
                    cOTASupervisorId: highlighted.cOTASupervisorId ? highlighted.cOTASupervisorId.toString() : null,
                    pTServicesStartBy: highlighted.pTServicesStartBy ? moment(highlighted.pTServicesStartBy, 'YYYY-MM-DD') : '',
                    pTInitialIFSPDateSOS: highlighted.pTInitialIFSPDateSOS ? moment(highlighted.pTInitialIFSPDateSOS, 'YYYY-MM-DD') : '',
                    pTProviderSOSDate: highlighted.pTProviderSOSDate ? moment(highlighted.pTProviderSOSDate, 'YYYY-MM-DD') : '',
                    pTDateAssigned: highlighted.pTDateAssigned ? moment(highlighted.pTDateAssigned, 'YYYY-MM-DD') : '',
                    pTServiceFromAnotherAgency: highlighted?.pTServiceFromAnotherAgency,
                    physicalTherapistId: highlighted.physicalTherapistId ? highlighted.physicalTherapistId.toString() : null,
                    pTAId: highlighted.pTAId ? highlighted.pTAId.toString() : null,
                    pTASupervisorId: highlighted.pTASupervisorId ? highlighted.pTASupervisorId.toString() : null,
                    sTServicesStartBy: highlighted.sTServicesStartBy ? moment(highlighted.sTServicesStartBy, 'YYYY-MM-DD') : '',
                    sTInitialIFSPDateSOS: highlighted.sTInitialIFSPDateSOS ? moment(highlighted.sTInitialIFSPDateSOS, 'YYYY-MM-DD') : '',
                    sTProviderSOSDate: highlighted.sTProviderSOSDate ? moment(highlighted.sTProviderSOSDate, 'YYYY-MM-DD') : '',
                    sTDateAssigned: highlighted.sTDateAssigned ? moment(highlighted.sTDateAssigned, 'YYYY-MM-DD') : '',
                    sTServiceFromAnotherAgency: highlighted?.sTServiceFromAnotherAgency,
                    speechTherapistId: highlighted.speechTherapistId ? highlighted.speechTherapistId.toString() : null,
                    sLPCFId: highlighted.sLPCFId ? highlighted.sLPCFId.toString() : null,
                    quaterlyReport5Month: highlighted.quaterlyReport5Month ? moment(highlighted.quaterlyReport5Month, 'YYYY-MM-DD') : '',
                    quarterlyReport10Month: highlighted.quarterlyReport10Month ? moment(highlighted.quarterlyReport10Month, 'YYYY-MM-DD') : '',
                    otherReport: highlighted.otherReport ? moment(highlighted.otherReport, 'YYYY-MM-DD') : '',
                    dischargeReport: highlighted.dischargeReport ? moment(highlighted.dischargeReport, 'YYYY-MM-DD') : '',
                    exitSkills: highlighted.exitSkills ? highlighted.exitSkills : '',
                    notes: highlighted.notes ? highlighted.notes : '',
                    burmese: highlighted?.burmese,
                    chin: highlighted?.chin,
                    falamChin: highlighted?.falamChin,
                    hakhaChin: highlighted?.hakhaChin,
                    matuChin: highlighted?.matuChin,
                    tedimChin: highlighted?.tedimChin,
                    zanniatChin: highlighted?.zanniatChin,
                    zophieChin: highlighted?.zophieChin,
                    crioulo: highlighted?.crioulo,
                    filipino: highlighted?.filipino,
                    french: highlighted?.french,
                    frenchCajun: highlighted?.frenchCajun,
                    frenchCanadian: highlighted?.frenchCanadian,
                    haitainCreole: highlighted?.haitainCreole,
                    german: highlighted?.german,
                    punjabi: highlighted?.punjabi,
                    spanish: highlighted?.spanish,
                    vietnamese: highlighted?.vietnamese,
                    somali: highlighted?.somali,
                    swahili: highlighted?.swahili,
                    referralStatus: highlighted?.referralStatus ? highlighted?.referralStatus.toString() : '',
                    checkNotes: highlighted?.checkNotes
                })
            } else {
                form.setFieldsValue({
                    firstStepsId: "",
                    lastNameHyphenated: false,
                    county: '0',
                    dateOfBirth: "",
                    ageOut: "",
                    // address: highlighted.address,
                    // city: highlighted.city,
                    // zipcode: highlighted.zipcode,
                    virtualVisit: false,
                    dTConsult: "",
                    oTConsult: "",
                    pTConsult: "",
                    sTConsult: "",
                    dTNeeded: false,
                    oTNeeded: false,
                    pTNeeded: false,
                    sTNeeded: false,
                    dTFulfilled: false,
                    dTAFulfilled: false,
                    oTFulfilled: false,
                    cOTAFulfilled: false,
                    pTFulfilled: false,
                    pTAFulfilled: false,
                    sTFulfilled: false,
                    sLPCFFulfilled: false,
                    initialReferralDate: "",
                    consultReferral1Date: "",
                    consultReferral2Date: "",
                    consultReferral3Date: "",
                    consultReferral4Date: "",
                    ongoingReferral1Date: "",
                    ongoingReferral2Date: "",
                    ongoingReferral3Date: "",
                    ongoingReferral4Date: "",
                    addonReferral1Date: "",
                    addonReferral2Date: "",
                    addonReferral3Date: "",
                    addonReferral4Date: "",
                    // caseManagementNotes: highlighted.caseManagementNotes,
                    supplementalDate1: "",
                    supplementalService1: "0",
                    supplementalDate2: "",
                    supplementalService2: "0",
                    supplementalDate3: "",
                    supplementalService3: "0",
                    supplementalDate4: "",
                    supplementalService4: "0",
                    // doctorSignature: highlighted.doctorSignature,
                    dTServicesStartBy: "",
                    dTInitialIFSPDateSOS: "",
                    dTProviderSOSDate: "",
                    dTDateAssigned: "",
                    dTServiceFromAnotherAgency: false,
                    developmentalTherapistId: null,
                    dTCId: null,
                    dTCSupervisorId: null,
                    oTServicesStartBy: "",
                    oTInitialIFSPDateSOS: "",
                    oTProviderSOSDate: "",
                    oTDateAssigned: "",
                    oTServiceFromAnotherAgency: false,
                    occupationalTherapistId: null,
                    cOTAId: null,
                    cOTASupervisorId: null,
                    pTServicesStartBy: "",
                    pTInitialIFSPDateSOS: "",
                    pTProviderSOSDate: "",
                    pTDateAssigned: "",
                    pTServiceFromAnotherAgency: false,
                    physicalTherapistId: null,
                    pTAId: null,
                    pTASupervisorId: null,
                    sTServicesStartBy: "",
                    sTInitialIFSPDateSOS: "",
                    sTProviderSOSDate: "",
                    sTDateAssigned: "",
                    sTServiceFromAnotherAgency: false,
                    speechTherapistId: null,
                    sLPCFId: null,
                    quaterlyReport5Month: moment().add(5, 'months'),
                    quarterlyReport10Month: moment().add(10, 'months'),
                    otherReport: "",
                    dischargeReport: "",
                    // exitSkills: highlighted.exitSkills,
                    // notes: highlighted.notes,
                    burmese: false,
                    chin: false,
                    falamChin: false,
                    hakhaChin: false,
                    matuChin: false,
                    tedimChin: false,
                    zanniatChin: false,
                    zopheiChin: false,
                    crioulo: false,
                    filipino: false,
                    french: false,
                    frenchCajun: false,
                    frenchCanadian: false,
                    haitainCreole: false,
                    german: false,
                    punjabi: false,
                    spanish: false,
                    vietnamese: false,
                    somali: false,
                    swahili: false,
                    referralStatus: "0",
                    checkNotes: false
                })
            }
        }
    }, [visibility]);

    const onClose = () => {
        form.resetFields();
        setVisibility(false);
        setFormType();
        setFormContext({});
        setPage(0);
    }

    const onNotesCancel = () => {
        setNotesVisible(false);
        onClose();
    }

    const onNotesAcknowledge = () => {
        setNotesVisible(false);
    }

    const putRequest = () => {
        form.validateFields(allFormKeys).then(values => {
            SequentialMessageNotifier(formType);
            setLoading(true);
            values = {
                ...values,
                iFSPDate: values.ifspDate ? moment(values.ifspDate).format('YYYY-MM-DD') : '',
                dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).format('YYYY-MM-DD') : '',
                ageOut: values.ageOut ? moment(values.ageOut).format('YYYY-MM-DD') : '',
                dTConsult: values.dTConsult ? moment(values.dTConsult).format('YYYY-MM-DD') : '',
                oTConsult: values.oTConsult ? moment(values.oTConsult).format('YYYY-MM-DD') : '',
                pTConsult: values.pTConsult ? moment(values.pTConsult).format('YYYY-MM-DD') : '',
                sTConsult: values.sTConsult ? moment(values.sTConsult).format('YYYY-MM-DD') : '',
                initialReferralDate: values.initialReferralDate ? moment(values.initialReferralDate).format('YYYY-MM-DD') : '',
                consultReferral1Date: values.consultReferral1Date ? moment(values.consultReferral1Date).format('YYYY-MM-DD') : '',
                consultReferral2Date: values.consultReferral2Date ? moment(values.consultReferral2Date).format('YYYY-MM-DD') : '',
                consultReferral3Date: values.consultReferral3Date ? moment(values.consultReferral3Date).format('YYYY-MM-DD') : '',
                consultReferral4Date: values.consultReferral4Date ? moment(values.consultReferral4Date).format('YYYY-MM-DD') : '',
                ongoingReferral1Date: values.ongoingReferral1Date ? moment(values.ongoingReferral1Date).format('YYYY-MM-DD') : '',
                ongoingReferral2Date: values.ongoingReferral2Date ? moment(values.ongoingReferral2Date).format('YYYY-MM-DD') : '',
                ongoingReferral3Date: values.ongoingReferral3Date ? moment(values.ongoingReferral3Date).format('YYYY-MM-DD') : '',
                ongoingReferral4Date: values.ongoingReferral4Date ? moment(values.ongoingReferral4Date).format('YYYY-MM-DD') : '',
                addonReferral1Date: values.addonReferral1Date ? moment(values.addonReferral1Date).format('YYYY-MM-DD') : '',
                addonReferral2Date: values.addonReferral2Date ? moment(values.addonReferral2Date).format('YYYY-MM-DD') : '',
                addonReferral3Date: values.addonReferral3Date ? moment(values.addonReferral3Date).format('YYYY-MM-DD') : '',
                addonReferral4Date: values.addonReferral4Date ? moment(values.addonReferral4Date).format('YYYY-MM-DD') : '',
                supplementalDate1: values.supplementalDate1 ? moment(values.supplementalDate1).format('YYYY-MM-DD') : '',
                supplementalDate2: values.supplementalDate2 ? moment(values.supplementalDate2).format('YYYY-MM-DD') : '',
                supplementalDate3: values.supplementalDate3 ? moment(values.supplementalDate3).format('YYYY-MM-DD') : '',
                supplementalDate4: values.supplementalDate4 ? moment(values.supplementalDate4).format('YYYY-MM-DD') : '',
                dTServicesStartBy: values.dTServicesStartBy ? moment(values.dTServicesStartBy).format('YYYY-MM-DD') : '',
                dTInitialIFSPDateSOS: values.dTInitialIFSPDateSOS ? moment(values.dTInitialIFSPDateSOS).format('YYYY-MM-DD') : '',
                dTProviderSOSDate: values.dTProviderSOSDate ? moment(values.dTProviderSOSDate).format('YYYY-MM-DD') : '',
                dTDateAssigned: values.dTDateAssigned ? moment(values.dTDateAssigned).format('YYYY-MM-DD') : '',
                oTServicesStartBy: values.oTServicesStartBy ? moment(values.oTServicesStartBy).format('YYYY-MM-DD') : '',
                oTInitialIFSPDateSOS: values.oTInitialIFSPDateSOS ? moment(values.oTInitialIFSPDateSOS).format('YYYY-MM-DD') : '',
                oTProviderSOSDate: values.oTProviderSOSDate ? moment(values.oTProviderSOSDate).format('YYYY-MM-DD') : '',
                oTDateAssigned: values.oTDateAssigned ? moment(values.oTDateAssigned).format('YYYY-MM-DD') : '',
                pTServicesStartBy: values.pTServicesStartBy ? moment(values.pTServicesStartBy).format('YYYY-MM-DD') : '',
                pTInitialIFSPDateSOS: values.pTInitialIFSPDateSOS ? moment(values.pTInitialIFSPDateSOS).format('YYYY-MM-DD') : '',
                pTProviderSOSDate: values.pTProviderSOSDate ? moment(values.pTProviderSOSDate).format('YYYY-MM-DD') : '',
                pTDateAssigned: values.pTDateAssigned ? moment(values.pTDateAssigned).format('YYYY-MM-DD') : '',
                sTServicesStartBy: values.sTServicesStartBy ? moment(values.sTServicesStartBy).format('YYYY-MM-DD') : '',
                sTInitialIFSPDateSOS: values.sTInitialIFSPDateSOS ? moment(values.sTInitialIFSPDateSOS).format('YYYY-MM-DD') : '',
                sTProviderSOSDate: values.sTProviderSOSDate ? moment(values.sTProviderSOSDate).format('YYYY-MM-DD') : '',
                sTDateAssigned: values.sTDateAssigned ? moment(values.sTDateAssigned).format('YYYY-MM-DD') : '',
                quaterlyReport5Month: values.quaterlyReport5Month ? moment(values.quaterlyReport5Month).format('YYYY-MM-DD') : '',
                quarterlyReport10Month: values.quarterlyReport10Month ? moment(values.quarterlyReport10Month).format('YYYY-MM-DD') : '',
                otherReport: values.otherReport ? moment(values.otherReport).format('YYYY-MM-DD') : '',
                dischargeReport: values.dischargeReport ? moment(values.dischargeReport).format('YYYY-MM-DD') : '',
                referralStatus: values.referralStatus ? parseInt(values.referralStatus) : 0,
                supplementalService1: values.supplementalService1 ? parseInt(values.supplementalService1) : 0,
                supplementalService2: values.supplementalService2 ? parseInt(values.supplementalService2) : 0,
                supplementalService3: values.supplementalService3 ? parseInt(values.supplementalService3) : 0,
                supplementalService4: values.supplementalService4 ? parseInt(values.supplementalService4) : 0,
                county: values.county ? parseInt(values.county) : 0,
            };
            EditClient(highlighted.id, values)
                .then(res => {
                    if (res && res.data) {
                        SequentialMessageNotifier(formType, 'success');
                        handleAfterFormSubmit(res.data);
                        onClose();
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data.ModelState) {
                            let obj = error.response.data.ModelState;
                            SequentialMessageNotifier(formType, 'error', obj);
                        } else if (error.response.data.Message) {
                            SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    };

    const postRequest = () => {
        form.validateFields(allFormKeys).then(values => {
            SequentialMessageNotifier(formType);
            setLoading(true);
            values = {
                ...values,
                iFSPDate: values.ifspDate ? moment(values.ifspDate).format('YYYY-MM-DD') : '',
                dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).format('YYYY-MM-DD') : '',
                ageOut: values.ageOut ? moment(values.ageOut).format('YYYY-MM-DD') : '',
                dTConsult: values.dTConsult ? moment(values.dTConsult).format('YYYY-MM-DD') : '',
                oTConsult: values.oTConsult ? moment(values.oTConsult).format('YYYY-MM-DD') : '',
                pTConsult: values.pTConsult ? moment(values.pTConsult).format('YYYY-MM-DD') : '',
                sTConsult: values.sTConsult ? moment(values.sTConsult).format('YYYY-MM-DD') : '',
                initialReferralDate: values.initialReferralDate ? moment(values.initialReferralDate).format('YYYY-MM-DD') : '',
                consultReferral1Date: values.consultReferral1Date ? moment(values.consultReferral1Date).format('YYYY-MM-DD') : '',
                consultReferral2Date: values.consultReferral2Date ? moment(values.consultReferral2Date).format('YYYY-MM-DD') : '',
                consultReferral3Date: values.consultReferral3Date ? moment(values.consultReferral3Date).format('YYYY-MM-DD') : '',
                consultReferral4Date: values.consultReferral4Date ? moment(values.consultReferral4Date).format('YYYY-MM-DD') : '',
                ongoingReferral1Date: values.ongoingReferral1Date ? moment(values.ongoingReferral1Date).format('YYYY-MM-DD') : '',
                ongoingReferral2Date: values.ongoingReferral2Date ? moment(values.ongoingReferral2Date).format('YYYY-MM-DD') : '',
                ongoingReferral3Date: values.ongoingReferral3Date ? moment(values.ongoingReferral3Date).format('YYYY-MM-DD') : '',
                ongoingReferral4Date: values.ongoingReferral4Date ? moment(values.ongoingReferral4Date).format('YYYY-MM-DD') : '',
                addonReferral1Date: values.addonReferral1Date ? moment(values.addonReferral1Date).format('YYYY-MM-DD') : '',
                addonReferral2Date: values.addonReferral2Date ? moment(values.addonReferral2Date).format('YYYY-MM-DD') : '',
                addonReferral3Date: values.addonReferral3Date ? moment(values.addonReferral3Date).format('YYYY-MM-DD') : '',
                addonReferral4Date: values.addonReferral4Date ? moment(values.addonReferral4Date).format('YYYY-MM-DD') : '',
                supplementalDate1: values.supplementalDate1 ? moment(values.supplementalDate1).format('YYYY-MM-DD') : '',
                supplementalDate2: values.supplementalDate2 ? moment(values.supplementalDate2).format('YYYY-MM-DD') : '',
                supplementalDate3: values.supplementalDate3 ? moment(values.supplementalDate3).format('YYYY-MM-DD') : '',
                supplementalDate4: values.supplementalDate4 ? moment(values.supplementalDate4).format('YYYY-MM-DD') : '',
                dTServicesStartBy: values.dTServicesStartBy ? moment(values.dTServicesStartBy).format('YYYY-MM-DD') : '',
                dTInitialIFSPDateSOS: values.dTInitialIFSPDateSOS ? moment(values.dTInitialIFSPDateSOS).format('YYYY-MM-DD') : '',
                dTProviderSOSDate: values.dTProviderSOSDate ? moment(values.dTProviderSOSDate).format('YYYY-MM-DD') : '',
                dTDateAssigned: values.dTDateAssigned ? moment(values.dTDateAssigned).format('YYYY-MM-DD') : '',
                oTServicesStartBy: values.oTServicesStartBy ? moment(values.oTServicesStartBy).format('YYYY-MM-DD') : '',
                oTInitialIFSPDateSOS: values.oTInitialIFSPDateSOS ? moment(values.oTInitialIFSPDateSOS).format('YYYY-MM-DD') : '',
                oTProviderSOSDate: values.oTProviderSOSDate ? moment(values.oTProviderSOSDate).format('YYYY-MM-DD') : '',
                oTDateAssigned: values.oTDateAssigned ? moment(values.oTDateAssigned).format('YYYY-MM-DD') : '',
                pTServicesStartBy: values.pTServicesStartBy ? moment(values.pTServicesStartBy).format('YYYY-MM-DD') : '',
                pTInitialIFSPDateSOS: values.pTInitialIFSPDateSOS ? moment(values.pTInitialIFSPDateSOS).format('YYYY-MM-DD') : '',
                pTProviderSOSDate: values.pTProviderSOSDate ? moment(values.pTProviderSOSDate).format('YYYY-MM-DD') : '',
                pTDateAssigned: values.pTDateAssigned ? moment(values.pTDateAssigned).format('YYYY-MM-DD') : '',
                sTServicesStartBy: values.sTServicesStartBy ? moment(values.sTServicesStartBy).format('YYYY-MM-DD') : '',
                sTInitialIFSPDateSOS: values.sTInitialIFSPDateSOS ? moment(values.sTInitialIFSPDateSOS).format('YYYY-MM-DD') : '',
                sTProviderSOSDate: values.sTProviderSOSDate ? moment(values.sTProviderSOSDate).format('YYYY-MM-DD') : '',
                sTDateAssigned: values.sTDateAssigned ? moment(values.sTDateAssigned).format('YYYY-MM-DD') : '',
                quaterlyReport5Month: values.quaterlyReport5Month ? moment(values.quaterlyReport5Month).format('YYYY-MM-DD') : '',
                quarterlyReport10Month: values.quarterlyReport10Month ? moment(values.quarterlyReport10Month).format('YYYY-MM-DD') : '',
                otherReport: values.otherReport ? moment(values.otherReport).format('YYYY-MM-DD') : '',
                dischargeReport: values.dischargeReport ? moment(values.dischargeReport).format('YYYY-MM-DD') : '',
                referralStatus: values.referralStatus ? parseInt(values.referralStatus) : 0,
                supplementalService1: values.supplementalService1 ? parseInt(values.supplementalService1) : 0,
                supplementalService2: values.supplementalService2 ? parseInt(values.supplementalService2) : 0,
                supplementalService3: values.supplementalService3 ? parseInt(values.supplementalService3) : 0,
                supplementalService4: values.supplementalService4 ? parseInt(values.supplementalService4) : 0,
                county: values.county ? parseInt(values.county) : 0,
            };
            CreateClient(values)
                .then(res => {
                    if (res && res.data) {
                        SequentialMessageNotifier(formType, 'success');
                        handleAfterFormSubmit(res.data);
                        onClose();
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.data.ModelState) {
                            let obj = error.response.data.ModelState;
                            SequentialMessageNotifier(formType, 'error', obj);
                        } else if (error.response.data.Message) {
                            SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    }

    const onSubmit = () => {
        reusableSubmitValidatorWithKeys(allFormKeys, allFormKeys, formType, postRequest, putRequest, form);
    }

    const prev = () => {
        setPage(page - 1);
    }

    const next = () => {
        form.validateFields().then(() => {
            setPage(page + 1)
        })
    }

    let fieldsChanged = {}

    const onFormChange = (name, { changedFields, forms }) => {
        let changedField = changedFields[0].name[0];
        fieldsChanged[changedField] = true;
    }

    const onBlurChange = (id, value) => {
        switch (id) {
            case 'firstName1':
                if (value) form.setFieldsValue({ preferredName: value });
                break
        }
    }

    const steps = [
        {
            title: 'Personal',
            content: (
                <PersonalAndAddress form={form} onBlurChange={onBlurChange} />
            ),
        },
        {
            title: 'Guardians',
            content: (
                <Guardians form={form} />
            ),
        },
        {
            title: 'Services',
            content: (
                <Services form={form} formContext={formContext} setFormContext={setFormContext} />
            ),
        },
        {
            title: 'Therapists',
            content: (
                <Therapists form={form} formContext={formContext} setFormContext={setFormContext} />
            ),
        },
        {
            title: 'Languages',
            content: (
                <Languages form={form} />
            ),
        },
    ]

    return (
        <>
            <Modal
                title={
                    <Steps
                        style={{ borderRadius: '10px' }}
                        current={page}
                        onChange={stepNum => setPage(stepNum)}
                        direction="horizontal"
                    >
                        {steps.map(i => (
                            <Step className="step-title" key={i.title} title={i.title} />
                        ))}
                    </Steps>
                }
                onCancel={() => onClose()}
                open={visibility}
                onOk={() => onSubmit()}
                width="50%"
                closeIcon={false}
                footer={
                    <>
                        <Popconfirm title="Are you sure?" placement="bottomRight" onConfirm={() => onClose()}>
                            <Button loading={loading} type="primary" danger>
                                Cancel
                            </Button>
                        </Popconfirm>
                        <Button
                            type="primary"
                            loading={loading}
                            ghost
                            onClick={() => prev()}
                            style={{
                                display:
                                    page >= 1 ? '' : 'none',
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            type="primary"
                            loading={loading}
                            ghost
                            onClick={() => next()}
                            style={{
                                display:
                                    page === steps.length ? 'none' : '',
                            }}
                        >
                            Next
                        </Button>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={() => onSubmit()}
                        >
                            Submit
                        </Button>
                    </>
                }
            >
                <Form.Provider onFormChange={(name, { changedFields, forms }) => onFormChange(name, { changedFields, forms })} >
                    <div>
                        {steps[page]?.content}
                    </div>
                </Form.Provider>
            </Modal>
            <Modal
                title="Important Notes"
                open={notesVisible}
                okText="I affirm that I have read and understood the notes on this popup"
                onCancel={() => onNotesCancel()}
                onOk={() => onNotesAcknowledge()}
            >
                <Card size='small' className='formCard'>
                    {highlighted?.notes}
                </Card>
            </Modal>
        </>
    )
}

export default ClientForm;