export const allFormKeys = [
    'firstStepsId',
    'iFSPDate',
    'firstName1',
    'firstName2',
    'preferredName',
    'lastName1',
    'lastName2',
    'lastNameHyphenated',
    'county',
    'dateOfBirth',
    'ageOut',
    'address',
    'city',
    'zipcode',
    'parentOneFirstName1',
    'parentOneFirstName2',
    'parentOnePreferredName',
    'parentOneLastName1',
    'parentOneLastName2',
    'parentOneLastNameHyphenated',
    'parentOneAddress1',
    'parentOneAddress2',
    'parentOneCity',
    'parentOneState',
    'parentOneZipcode',
    'parentOnePhone',
    'parentOneNotes',
    'parentTwoFirstName1',
    'parentTwoFirstName2',
    'parentTwoPreferredName',
    'parentTwoLastName1',
    'parentTwoLastName2',
    'parentTwoLastNameHyphenated',
    'parentTwoAddress1',
    'parentTwoAddress2',
    'parentTwoCity',
    'parentTwoState',
    'parentTwoZipcode',
    'parentTwoPhone',
    'parentTwoNotes',
    'fosterParentFirstName1',
    'fosterParentFirstName2',
    'fosterParentPreferredName',
    'fosterParentLastName1',
    'fosterParentLastName2',
    'fosterParentLastNameHyphenated',
    'fosterParentAddress1',
    'fosterParentAddress2',
    'fosterParentCity',
    'fosterParentState',
    'fosterParentZipcode',
    'fosterParentPhone',
    'fosterParentNotes',
    'guardianAdLitumParentFirstName1',
    'guardianAdLitumFirstName2',
    'guardianAdLitumPreferredName',
    'guardianAdLitumLastName1',
    'guardianAdLitumLastName2',
    'guardianAdLitumLastNameHyphenated',
    'guardianAdLitumAddress1',
    'guardianAdLitumAddress2',
    'guardianAdLitumCity',
    'guardianAdLitumState',
    'guardianAdLitumZipcode',
    'guardianAdLitumPhone',
    'guardianAdLitumNotes',
    'guardianFirstName1',
    'guardianFirstName2',
    'guardianPreferredName',
    'guardianLastName1',
    'guardianLastName2',
    'guardianLastNameHyphenated',
    'guardianAddress1',
    'guardianAddress2',
    'guardianCity',
    'guardianState',
    'guardianZipcode',
    'guardianPhone',
    'guardianNotes',
    'serviceCoordinatorId',
    'serviceCoordinatorNotes',
    'virtualVisit',
    'dTConsult',
    'oTConsult',
    'pTConsult',
    'sTConsult',
    'dTNeeded',
    'oTNeeded',
    'pTNeeded',
    'sTNeeded',
    'dTFulfilled',
    'dTAFulfilled',
    'oTFulfilled',
    'cOTAFulfilled',
    'pTFulfilled',
    'pTAFulfilled',
    'sTFulfilled',
    'sLPCFFulfilled',
    'initialReferralDate',
    'consultReferral1Date',
    'consultReferral2Date',
    'consultReferral3Date',
    'consultReferral4Date',
    'ongoingReferral1Date',
    'ongoingReferral2Date',
    'ongoingReferral3Date',
    'ongoingReferral4Date',
    'addonReferral1Date',
    'addonReferral2Date',
    'addonReferral3Date',
    'addonReferral4Date',
    'caseManagementNotes',
    'supplementalDate1',
    'supplementalService1',
    'supplementalDate2',
    'supplementalService2',
    'supplementalDate3',
    'supplementalService3',
    'supplementalDate4',
    'supplementalService4',
    'doctorSignature',
    'dTServicesStartBy',
    'dTInitialIFSPDateSOS',
    'dTProviderSOSDate',
    'dTDateAssigned',
    'dTServiceFromAnotherAgency',
    'developmentalTherapistId',
    'dTCId',
    'dTCSupervisorId',
    'oTServicesStartBy',
    'oTInitialIFSPDateSOS',
    'oTProviderSOSDate',
    'oTDateAssigned',
    'oTServiceFromAnotherAgency',
    'occupationalTherapistId',
    'cOTAId',
    'cOTASupervisorId',
    'pTServicesStartBy',
    'pTInitialIFSPDateSOS',
    'pTProviderSOSDate',
    'pTDateAssigned',
    'pTServiceFromAnotherAgency',
    'physicalTherapistId',
    'pTAId',
    'pTASupervisorId',
    'sTServicesStartBy',
    'sTInitialIFSPDateSOS',
    'sTProviderSOSDate',
    'sTDateAssigned',
    'sTServiceFromAnotherAgency',
    'speechTherapistId',
    'sLPCFId',
    'quaterlyReport5Month',
    'quarterlyReport10Month',
    'otherReport',
    'dischargeReport',
    'exitSkills',
    'notes',
    'burmese',
    'chin',
    'falamChin',
    'hakhaChin',
    'matuChin',
    'tedimChin',
    'zanniatChin',
    'zophieChin',
    'crioulo',
    'filipino',
    'french',
    'frenchCajun',
    'frenchCanadian',
    'haitainCreole',
    'german',
    'punjabi',
    'spanish',
    'vietnamese',
    'somali',
    'swahili',
    'referralStatus'
]